<template>
  <b-card>
    <b-row class="mb-5">
      <b-col md="6">
        <h4 class="mt-2">Ödünç Alınan Kitaplar Listesi</h4>
      </b-col>
    </b-row>
    <b-table-simple
        borderless
        :responsive="true"
        table-class="table-vertical-center"
    >
      <b-thead>
        <b-tr>
          <b-th>Alan Kişi</b-th>
          <b-th>{{ $t('project.book') }}</b-th>
          <b-th>{{ $t('project.authors') }}</b-th>
          <b-th>{{ $t('project.shelf') }}</b-th>
          <b-th>{{ $t('project.barcode') }}</b-th>
          <b-th>{{ $t('project.receivedDate') }}</b-th>
          <b-th>{{ $t('project.returnedDate') }}</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <template v-if="!loading">
          <b-tr v-for="(item, i) in books" :key="i">
            <b-td>{{ item.loans[0].user.fullName }}</b-td>
            <b-td>{{ item.title }}</b-td>
            <b-td>{{ item.authors.map(a => a.fullName).join(', ') }}</b-td>
            <b-td>{{ item.shelf }}</b-td>
            <b-td>{{ item.barcode }}</b-td>
            <td> {{ item.loans[0].startDate | formatDate(1) }}</td>
            <td>{{ item.loans[0].endDate | formatDate(1) }}</td>
          </b-tr>
        </template>
        <tr v-if="books.length === 0 && !loading" class="text-center">
          <td colspan="8">{{ $t('project.noRecord') }}</td>
        </tr>
        <tr v-if="loading" class="text-center">
          <td colspan="8"><b-spinner variant="primary" /></td>
        </tr>
      </b-tbody>
    </b-table-simple>
    <b-pagination
        v-if="pageSize < totalRows"
        v-model="pageNumber"
        :total-rows="totalRows"
        :per-page="pageSize"
        aria-controls="my-table"
    ></b-pagination>
  </b-card>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {

  data() {
    return {
      books: [],
      pageSize: 10,
      pageNumber: 1,
      totalRows: null,
      loading: true,
      search: '',
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'project.adminPanel' },
      { title: 'project.bookTransactions' }
    ]);

    this.getLoanedBooks();
  },
  methods: {
    async getLoanedBooks() {
      try {
        this.loading = true;
        const { data } = await this.axios.get("lib/books/loanedBooks", {
          params: { ...this.requestQuery }
        });
        this.books = data.rows;
        this.totalRows = data.count;
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },

    async deleteBook(id) {
      try {
        const { isConfirmed } = await this.confirmDelete();

        if (isConfirmed) {
          await this.axios.delete('lib/books/' + id);

          this.toast({ message: 'deleteSuccess', item: 'book' });
          this.getLoanedBooks();
        }

      } catch (e) {
        this.toast({ type: "danger", message: "deleteError", item: "book" });
        console.log(e);
      }
    },

  },
  computed: {
    requestQuery() {
      return {
        page: {
          size: this.pageSize,
          number: this.pageNumber
        },
        search: {
          title: this.search
        },
      };
    }
  },
  watch: {
    requestQuery() {
      this.getLoanedBooks();
    }
  }
};
</script>
